import { Link } from "react-router-dom";
//import NavMenu from "../Home/components/NavMenu";
import "./style-service.css";
import NavBar from "../Home/components/NavBar";

export default function Services(data) {
var divBox;
var LinkNutrBox;
var LinkTrainBox;
var LinkPaqBox;
var service;

console.log(data.modo);

if (data.modo === "users")
{
    service = data.modo+"/nutricion";  
    divBox = <div className="of-dis">QUE OFERTAS DISPONIBLES DESEAS ELEGIR</div>;
    LinkNutrBox = <Link to={service}><button type="button" className="service-nutr"></button></Link>;
    service = data.modo+"/personal-trainer";      
    LinkTrainBox = <Link to={service}><button type="button" className="service-train"></button></Link>;
    service = data.modo+"/paq-saludable";   
    LinkPaqBox = <Link to={service}><button type="button" className="service-paq"></button></Link>;    
}
if (data.modo==="trainer")
{
    service = data.modo+"/nutricion";  
    divBox = <div className="of-dis">A QUE OFERTAS DISPONIBLES DESEAS APLICAR</div>;
    LinkNutrBox = <Link to={service}><button type="button" className="service-nutr"></button></Link>;
    service = data.modo+"/personal-trainer";      
    LinkTrainBox = <Link to={service}><button type="button" className="service-train"></button></Link>;
    service = data.modo+"/paq-saludable";   
    LinkPaqBox = <Link to={service}><button type="button" className="service-paq"></button></Link>;    

}

return (
    <>
    {divBox}
    <div id="servicios">
    {LinkNutrBox}
    </div>
    <div id="servicios">
    {LinkTrainBox}
    </div>    
    <div id="servicios">
    {LinkPaqBox}
    </div>  
    <NavBar/>        
    </>              
);
}

