import 'bootstrap/dist/css/bootstrap.min.css';
import "./style-admin-offers.css";
import {useState} from 'react';
import Modal from '../../../Modal';

import UpdateAppliedOffer from '../UpdateAppliedOffer';

export default function OfferResultItem({ProfessionalId,Timestamp,TrainingArea, UserOfferId,FullName,JobType,OfferDate,CityLocation,PayOffer,BasicInfo}) 
{
    const [isOpen, setIsOpen] = useState(false);

    const handleUpdateOfferClick = (e) => {
        console.log("Clicked");
        setIsOpen(true);
       }
 
    return (
      <>
        <Modal
          open={isOpen}
          close={() => setIsOpen(false)}
          openstyle="m-apl-dialog-opened"
          closestyle="m-dialog-closed"
          >
          <div>
            <UpdateAppliedOffer
              results={{
                ProfessionalId,
                UserOfferId,
                TrainingArea,
                CityLocation,
                JobType,
                Timestamp,
                FullName: FullName,
                telefono: BasicInfo.M.InfoPersonal.M.telefono.S,
                tipo_servicio: BasicInfo.M.ObjetivoOferta.M.tipo_servicio.S,
                clase_servicio: BasicInfo.M.ObjetivoOferta.M.clase_servicio.S,
                PayOffer: PayOffer.toString(),
                vr_oferta: BasicInfo.M.ObjetivoOferta.M.vr_oferta.S  
              }}
              isSearching={TrainingArea}
              close={(e) => setIsOpen(false)}              
            />
          </div>
        </Modal>
        <div>
          <div className="row row-cols-1 row-cols-md-3 mb-4 text-center justify-content-center  d-flex align-items-center align-self-center ">
            <div className="col">
              <button
                type="button"
                className="btn-admin-apli"
                onClick={handleUpdateOfferClick}
              >
                <div className="card-apli">
                <div className="card-header-apli py-3">
                    <h1 className="my-0 fw-normal">
                    {TrainingArea}
                    </h1>
                  </div>                  
                  <div className="card-header-apli py-3">
                    <h1 className="my-0 fw-normal">
                      {BasicInfo.M.ObjetivoOferta.M.tipo_servicio.S}
                    </h1>
                  </div>
                  <div className="card-body-apli">
                    <h1 className="card-title pricing-card-title">
                      ${PayOffer}
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <h2>USUARIO: {FullName}</h2>
                      </li>
                      <li>
                        <h2>
                          SERVICIO:{" "}
                          {BasicInfo.M.ObjetivoOferta.M.clase_servicio.S}
                        </h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    );
}