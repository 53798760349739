import Publicity from "./components/Publicity";
import Authenticate from "./components/Authenticate";
//import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

export default function Home() {
return (
        <div height={ '100%' }>
        {/* <NavMenu />  */}
        <Publicity /> 
        <Authenticate />
        <NavBar />
        <Footer /> 
        </div> 
    );
}