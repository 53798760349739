import { useState } from 'react';
import { useNavigate  } from "react-router-dom";
import "./style-reg.css";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

export default function SignUp() {

    const [nameText, setNameText] = useState("");
    const [documText, setDocumText] = useState(""); 
    const [userText, setUserText] = useState("");
    const [profileText, setProfileText] = useState(""); 
    const [telText, setTelText] = useState("");
    const [pwdText, setPwdText] = useState(""); 
    const [pwd2Text, setPwd2Text] = useState("");
    const [aceptoText, setAceptoText] = useState(false);    

    const navigate = useNavigate();      
    var perfil="";
    var divbox;

    const handleChangeCheckBox = () => {
        setAceptoText(!aceptoText);
      };       

    function validataData(){
        if (nameText==="")
        {
            alert("Debe ingresar el Campo Nombre Completo");
            return(false);
        }
        if (documText==="")
        {
            alert("Debe ingresar el Campo Documento de Identidad");
            return(false);            
        }
        if (userText==="")
        {
            alert("Debe ingresar el Campo Correo Electrónico");
            return(false);            
        }
        if (profileText==="")
        {
            alert("Debe seleccionar un Perfil (Usuario ó Profesional");
            return(false);            
        }
        if (telText==="")
        {
            alert("Debe ingresar el Campo Número de Contacto");
            return(false);            
        }
        if (pwdText==="")
        {
            alert("Debe ingresar el Campo Contraseña");
            return(false);            
        }
        if (pwd2Text==="")
        {
            alert("Debe ingresar el Campo Confirmar Contraseña");
            return(false);            
        }                                  
        return(true);
    }    

    if (pwd2Text.length !==0 && pwdText.length !==0 && pwd2Text !== pwdText){
        divbox = <h2 className="formulario__input-error">Las contraseñas no coinciden</h2>;
    }
    const handlePwdCompare = (val) => {
        setPwd2Text(val);   
    }

    const handleSignUpClick = (e) => { 
        if (!aceptoText)
        {
            alert("Debe Aceptar el Tratamiento de Datsos y los Terminos y Condiciones.");
        }
        else
        {
            if (validataData())
            {       
                var attributeList = [];

                if (profileText === 'Profesional')
                {
                    perfil = 'Professional';
                }
                else
                {
                    perfil = 'Customer';
                };

                var dataEmail = {
                    Name : 'email',
                    Value : userText
                };

                var dataPerfil = {
                    Name: "custom:perfil",
                    Value: perfil
                }
                var dataFullname = {
                Name: "custom:fullname",
                Value: nameText
                }
                var dataCity = {
                    Name: "custom:city",
                    Value: "Colombia"
                }        
                var dataDocumento = {
                Name: "custom:documento",
                Value: documText
                }
                var dataTelefono = {
                Name: "custom:telefono",
                Value: telText
                }       
                    
                var attributeEmail = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
                var attributePerfil = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataPerfil);
                var attributeFullname = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataFullname);
                var attributeCity = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataCity);                
                var attributeDoc = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataDocumento); 
                var attributeTel = 
                new AmazonCognitoIdentity.CognitoUserAttribute(dataTelefono); 
                                
                attributeList.push(attributeEmail);
                attributeList.push(attributePerfil);
                attributeList.push(attributeFullname);
                attributeList.push(attributeCity); 
                attributeList.push(attributeDoc); 
                attributeList.push(attributeTel);                                
                userPool.signUp(userText, pwdText, attributeList, null, function (err, result) {
                    if (err) {
                        alert(err);
                    } else {
                        navigate('/confirm/'+userText, { replace: true });                    
                    }
                }); 
                e.preventDefault();
                console.log('The link was clicked');
            }
        }              
    }   

return (
    <div className="contenedor__sign-up" 
    >
        <form action="" //</div>className="formulario__login"
        >
            <input 
            id="regfullname"
            type="text" 
            placeholder="Nombre Completo"
            value={nameText}
            onChange={({target: { value }}) => setNameText(value)}               
            />
            <input id="regdocument" 
            type="text" 
            placeholder="Documento de Identidad"
            value={documText}
            onChange={({target: { value }}) => setDocumText(value)}                   
            />
            <input 
            id="regusername" 
            type="email" 
            placeholder="Correo Electrónico"
            value={userText}
            onChange={({target: { value }}) => setUserText(value)}             
            />
            <input id="regperfil" 
            type="text" 
            placeholder="Perfil" list="browsers" 
            value={profileText}
            onChange={({target: { value }}) => setProfileText(value)}                       
            />
                <datalist id="browsers">
                    <option>Usuario</option>
                    <option>Profesional</option>
                </datalist>
            <input id="regcelular" 
            type="text" 
            placeholder="Número Celular"
            value={telText}
            onChange={({target: { value }}) => setTelText(value)}                   
            />                
            <input 
            id="regpassword"
            type="password"
            placeholder="Contraseña"
            value={pwdText}
            onChange={({target: { value }}) => setPwdText(value)}                   
            />
            <input 
            id="regconfirmpwd"
            type="password"
            placeholder="Confirmar contraseña"
            value={pwd2Text}
            onChange={({target: { value }}) => handlePwdCompare(value)}                   
            /> 
            {divbox}
            <div><i>La contraseña debe tener mínimo 8 caracteres, contener al menos una letra mayúscula y una minúscula, un caracter especial y un número.</i></div>                       
            <h2 className="formulario__input-error">Acepto el tratamiento de datos, así como los términos y condiciones del servicio.</h2>                
            <p>
            <a
              href="https://www.varoncare.com/Terminos y Condiciones.pdf"
              target="_blank"
              rel="noreferrer">Ver Terminos y Condiciones
            </a>               
            </p>
                <input 
                    className="contenedor__check-box"
                    type="checkbox" 
                    aria-label="Acepto Terminos"
                    id="Terms"
                    name="checkbox" 
                    checked={aceptoText}
                    onChange={handleChangeCheckBox}                     
                    required                     
                />
           <button onClick={handleSignUpClick} disabled={pwd2Text.length !==0 && pwdText.length !==0 && pwd2Text !== pwdText} className="boton-entrar" >REGISTRARSE</button>
        </form>
    </div>
 );
}