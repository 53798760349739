import 'bootstrap/dist/css/bootstrap.min.css';
import "./style-trainer.css";
import {useState} from 'react';
import Modal from '../../../Modal';

import ApplyOffer from '../ApplyOffer';

export default function OfferResultItem({TrainingArea, UserOfferId,FullName,CityLocation,JobType,OfferDate,PayOffer,OfferStatus,BasicInfo}) 
{
    const [isOpen, setIsOpen] = useState(false);

    const handleApplyOfferClick = (e) => {
        console.log("Clicked");
        if (window.confirm('Esta seguro que quiere aplicar a esta Oferta?'))
        {
          setIsOpen(true);
        }        
      }
 
    return (
      <div>
        <Modal
          open={isOpen}
          close={() => setIsOpen(false)}
          openstyle="m-apl-dialog-opened"
          closestyle="m-dialog-closed"
          >
          <div>
            <ApplyOffer
              results={{
                UserOfferId,
                TrainingArea,
                FullName,
                JobType,
                CityLocation,
                OfferDate,
                PayOffer,
                OfferStatus,
                BasicInfo,
              }}
              isSearching={TrainingArea}
              close={(e) => setIsOpen(false)}                    
            />
          </div>
        </Modal>
        <div>
          <div className="row row-cols-1 row-cols-md-3 mb-4 text-center justify-content-center  d-flex align-items-center align-self-center ">
            <div className="col">
              <button
                type="button"
                className="boton-trainer"
                onClick={handleApplyOfferClick}
              >
                <div className="card-trainer">
                  <div className="card-header-trainer py-3">
                    <h1 className="my-0 fw-normal"><b>
                      {BasicInfo.M.ObjetivoOferta.M.tipo_servicio.S}
                      </b>
                    </h1>
                  </div>
                  <div className="card-body-trainer">
                    <h1 className="card-title pricing-card-title">
                      ${PayOffer}
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <h2>USUARIO: {FullName}</h2>
                      </li>
                      <li>
                        <h2>
                          SERVICIO:{" "}
                          {BasicInfo.M.ObjetivoOferta.M.clase_servicio.S} {BasicInfo.M.ObjetivoOferta.M.personas_add.S}
                        </h2>
                      </li>
                      <li>
                        <h2>
                          CIUDAD:{" "}
                          {CityLocation}
                        </h2>
                      </li>
                      <li>
                        <h2>
                          FRANJA HORARIA:{" "}
                          {BasicInfo.M.ObjetivoOferta.M.franja_horaria.S}
                        </h2>
                      </li>                                              
                    </ul>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}