import "./style.css";
import imglogo from "../../../../assets/avcare_1.webp";
//import imglogopng from "../../../../assets/avcare_1.jpg";

export default function Publicity() {
return (
    <div className="home-page">
        <img className="video-box" alt="VaronCare Logo" src={imglogo} width={970} height={726} />
            {/* <picture>
                <source srcset={imglogo} type="image/webp"/>
                <img className="video-box" alt="VaronCare Logo" src={imglogopng} width="970px" heigth="726px" />
            </picture> */}
    </div>
     );
}