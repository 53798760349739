import { useState, useContext } from 'react';
import AuthContext from "../../../../context/authorizer";
import { useNavigate  } from "react-router-dom";
import "./comp-user.css";

    var apigClientFactory = require('aws-api-gateway-client').default;
    var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
    var apigClient = apigClientFactory.newClient(config);
    var pathParams = {
    //This is where path request params go.
    //userId: '1234',
    };
    // Template syntax follows url-template https://www.npmjs.com/package/url-template
    var pathTemplate = '/avhealthuserprofileresource';  
    var method = 'POST';
    var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
    var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
    var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);
    console.log(userPool);

export default function UserProfile(data) {
    const navigate = useNavigate();           
    const { auth, setAuth } = useContext(AuthContext);    
    const [dirText, setDirText] = useState(""); 
    const [paisText, setPaisText] = useState(""); 
    const [deptoText, setDeptoText] = useState(""); 
    const [muniText, setMuniText] = useState(""); 
    const [fechaText, setFechaText] = useState("");     
    const [estaturaText, setEstaturaText] = useState("");     
    const [descrText, setDescrText] = useState(""); 
    const [aceptoText, setAceptoText] = useState(false);

    const handleChangeCheckBox = () => {
      setAceptoText(!aceptoText);
    };    

    function validataData(){
      if (dirText==="")
      {
          alert("Debe ingresar el Campo Direccion");
          return(false);
      }
      if (paisText==="")
      {
          alert("Debe ingresar el Campo Pais");
          return(false);            
      }
      if (deptoText==="")
      {
          alert("Debe ingresar el Campo Departamento");
          return(false);            
      }
      if (muniText==="")
      {
          alert("Debe ingresar el Campo Municipio");
          return(false);            
      }
      if (fechaText==="")
      {
          alert("Debe ingresar la fecha de Nacimiento");
          return(false);            
      }
      if (estaturaText==="")
      {
          alert("Debe ingresar la Estatura");
          return(false);            
      }
      if (descrText==="")
      {
          alert("Debe diligenciar el Campo Descripcion Personal");
          return(false);            
      }      
      return(true);
    }

    const handleUpdateUserProfile = (e) => {
      if (!aceptoText)
      {
          alert("Debe Aceptar los Terminos y Condiciones.");
      }
      else
      {
          if (validataData())
          {
            var body = {
                    userId: auth.user,
                    CityLocation: auth.city,
                    FullName: auth.name,
                    BornDate: fechaText,
                    Phone: data.telefono,
                    height:  estaturaText, 
                    BasicData: {
                            "InfoPersonal": {
                              "M": {
                                "identificacion": {
                                  "S": data.document
                                },
                                "nombre_completo": {
                                  "S": auth.name 
                                },
                                "fecha_nacimiento": {
                                  "S": fechaText
                                },
                                "telefono": {
                                  "S": data.telefono 
                                },
                                "direccion": {
                                  "S": dirText 
                                },
                                "pais": {
                                    "S": paisText 
                                  },
                                  "departamento": {
                                    "S": deptoText 
                                  },
                                  "municipio": {
                                    "S": muniText 
                                  },                                                                                        
                                "estatura": {
                                  "S": estaturaText 
                                },                                                        
                                "descr_personal": {
                                  "S": descrText    
                                }
                              }
                            }
                        }                

                    };
            console.log(body);              
          
            var additionalParams = {headers: {Authorization: auth.tkn}};  
            apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(function(result){
                //This is where you would put a success callback
                alert("Los Datos del Usuario fueron actualizados correctamente"); 
                setAuth({user: "", pwd: "", name:"", city:"", documento:"", telefono:"", rol: null, tkn: null, isLogged: false, isEnabled: false });                                                   
                navigate('/', { replace: true });               
                console.log(result);            
            }).catch( function(result){
                //This is where you would put an error callback
                console.log(result);            
            });
          }
      }  
    }; 
    
    return (
    <div className="comp-user">
        <div className="container-comp-user">
            <h2>Perfil Usuario</h2>
            <br/>

            <div className="form-comp-user">
                <div>
                    <h4>Dirección</h4>
                </div>
                <input id="direccion" 
                name="direccion" 
                type="text" 
                placeholder="Dirección de residencia" 
                value={dirText}
                onChange={({target: { value }}) => setDirText(value)}   
                required />
            </div>
                <div className="form-comp-user">
                    <div>
                        <h4>País</h4>
                    </div>
                    <input id="pais" 
                    name="pais" 
                    type="text" 
                    placeholder="País de residencia"
                    value={paisText}
                    onChange={({target: { value }}) => setPaisText(value)}                                              
                    required 
                    />
                </div>
                <div className="form-comp-user">
                    <div>
                        <h4>Departamento</h4>
                    </div>
                    <input id="departamento" 
                    name="departamento" 
                    type="text" 
                    placeholder="Departamento de residencia"
                    value={deptoText}
                    onChange={({target: { value }}) => setDeptoText(value)}                       
                    required  
                     />
                </div>
                <div className="form-comp-user">
                    <div>
                        <h4>Municipio</h4>
                    </div>
                    <input id="municipio" 
                    name="municipio" 
                    type="text" 
                    placeholder="Municipio de residencia" 
                    value={muniText}
                    onChange={({target: { value }}) => setMuniText(value)}                     
                    required 
                    />
                </div>
            <div className="form-comp-user">
                <div>
                    <h4>Fecha de nacimiento</h4>
                </div>
                <input id="nacimiento" 
                name="nacimiento" 
                type="date" 
                value={fechaText}
                onChange={({target: { value }}) => setFechaText(value)}               
                required />
            </div>
            <div className="form-comp-user">
                <div>
                    <h4>Estatura</h4>
                </div>
                <input id="estatura" 
                name="estatura" 
                type="number" 
                placeholder="Ingrese su estatura en cms" 
                value={estaturaText}
                onChange={({target: { value }}) => setEstaturaText(value)}                  
                required />
            </div>
            <div className="form-comp-user">
                <div>
                    <h4>Descripción personal</h4>
                </div>
                <textarea id="descripcion" 
                name="descripcion" 
                maxLength="200" 
                cols="30" 
                rows="3"
                placeholder="Opcional. Escriba su descripción personal. Máx 200 caracteres"
                value={descrText}
                onChange={({target: { value }}) => setDescrText(value)}   
                ></textarea>
            </div>
            <div className="form-comp-user">
                    <p>Acepto los Terminos y Condiciones de este sitio.</p>
                    <p>
                      <a
                          href="https://www.varoncare.com/Terminos y Condiciones.pdf"
                          target="_blank"
                          rel="noreferrer">Ver Terminos y Condiciones
                      </a>               
                    </p>                      
                    <input 
                    type="checkbox" 
                    name="checkbox" 
                    id="checkbox"
                    checked={aceptoText}
                    onChange={handleChangeCheckBox}                     
                    required />
            </div>
            <div>
                <button type="button" className="btn-comp-user" onClick={handleUpdateUserProfile}>Enviar</button>
            </div>
        </div>
    </div>
     );
}