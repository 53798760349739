import { useState } from 'react';
//import pdfDoc from  "../assets/titulos_perfil.pdf";
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function PdfViewer(Docpdf) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    // <div>
    //   <Document file={pdfDoc} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page pageNumber={pageNumber} />
    //   </Document>
    //   <p>
    //     Page {pageNumber} of {numPages}
    //   </p>
    // </div>
       <Document file={Docpdf.document} >
         <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} width={670} />
       </Document>

  );
}