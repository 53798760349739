import { useState } from "react";
import OfferResults from "./components/ShowOffers";
//import NavMenu from "../Home/components/NavMenu";
import NavBar from "../Home/components/NavBar";
import "../Users/components/ShowOffers/style-users.css";
import data from "../../data/offers.json";

export default function Users(tiposerv) {
    const [ isLoading ] = useState(false);
    const [offersData ] = useState(data);   
 
    return (
        <div className="servicios-usuario">
        {/* <NavMenu/> */}
        <OfferResults results={offersData} isSearching = {isLoading} tiposervicio={tiposerv.service} />
        <NavBar/>        
        </div> 
    );
}