import { useState, useContext,useEffect } from 'react';
import AuthContext from "../../../../context/authorizer";
import { useParams  } from "react-router-dom";
import "./val-prof.css";
//import PdfViewer from '../../../../pdfviewer';
//import pdfdoc from "../../../../assets/titulos_perfil.pdf";
import ProfProfileDetail from './ProfProfileDetail';
import Loader from "../../../Loader";
import NavBar from "../../../Home/components/NavBar";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avhealthprofprofileresource/{professionalId}';  
var method = 'GET';

export default function ValidateProf(data) {
    const { auth } = useContext(AuthContext);
    const [response, setResponse] = useState("");  
    const { id } = useParams();  
    const [isLoading,setIsLoading] = useState(false);         

    pathParams = {professionalId: id};
        var additionalParams = {headers: {Authorization:auth.token}}; 

        useEffect(() => {
            const getUserDetail = async () => {
                    setIsLoading(true);
                    apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, null)
                    .then(function(result){
                    //This is where you would put a success callback
                    console.log(result); 
                    setResponse(result);           
                    if (result.headers['content-length'] === '2')
                    {
                        alert("Usuario no tiene los datos Actualizados.");    
                    }
                    setIsLoading(false);                    
                    }).catch( function(result){
                    //This is where you would put an error callback
                    console.log(result);            
                }); 
            };
            getUserDetail().catch(null);
        }, []);

    if (isLoading) {
        return(<>
        <Loader/>
        <NavBar/>
        </>);
    }
    else
    { 
        if (response==="")        
        {
            return(<div></div>);
        }
        else
        {return (
            <>
            <div className="card-header-validarprofesional">
               <ProfProfileDetail result={response}/>
            </div>
            <NavBar/>
            </>
        );
        }
    }
}