import { useContext,useState } from "react";
import AuthContext from "../../../../context/authorizer";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
 
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avhealthprofapplyofferresource';  
var method = 'POST';

export default function ApplyOffer(OfferData) {
 
    const { auth } = useContext(AuthContext);
    const [success,setSuccess] = useState(false);    

    const handleApplyOfferClick = (e) => {
    console.log("Clicked!!!");

    var timestamp = Date.now();
    var body = {
            ProfessionalId: auth.user,
            Timestamp: timestamp.toString(),
            UserOfferId: OfferData.results.UserOfferId,          
            TrainingArea: OfferData.results.TrainingArea,
            CityLocation: OfferData.results.CityLocation,
            FullName:OfferData.results.FullName,
            JobType: OfferData.results.JobType,
            OfferDate: timestamp.toString(), //OfferData.results.OfferDate,
            PayOffer: OfferData.results.PayOffer.toString(),
            OfferStatus: "pending-pay",
            BasicInfo: OfferData.results.BasicInfo.M
            };
  
    var additionalParams = {headers: {Authorization: auth.tkn}};  
    apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then(function(result){
        //This is where you would put a success callback
        setSuccess(true);
    }).catch( function(result){
        //This is where you would put an error callback
    });    
};
if (success)
{
    return (<>
        <div>
            Señor Profesional, ha aplicado correctamente a la Oferta. 
            Le notificaremos cuando puede iniciar el entrenamiento.
        </div>  
        <button onClick={OfferData.close} className="btn-apply">CERRAR</button>         
        </>)
}
else
{ 
return (<>
          <div>
            USUARIO: <h3> {OfferData.results.FullName}</h3>
            MODALIDAD: <h3>{OfferData.results.BasicInfo.M.ObjetivoOferta.M.tipo_servicio.S}</h3>
            SERVICIO: <h3>{OfferData.results.BasicInfo.M.ObjetivoOferta.M.clase_servicio.S}</h3>
            FRANJA HORARIA: <h3>{OfferData.results.BasicInfo.M.ObjetivoOferta.M.franja_horaria.S}</h3>            
          </div>
          <div>
            Objetivos y caracteristicas detalladas del Servicio:
            <br></br>
            <h3>{OfferData.results.JobType}</h3>               
          </div>
          <br></br>
          <div>
            UNA VEZ APLIQUES, NOTIFICAREMOS A NUESTRO USUARIO QUE SU OFERTA HA
            SIDO TOMADA.
          </div>            
            <button onClick={handleApplyOfferClick} className="btn-apply" >APLICAR</button> 
        </>)
}
};