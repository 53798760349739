import { useState } from 'react';
//import AuthContext from "../../../../../context/authorizer";
//import CurrentUserContext from '../../../Home';
import "./forgot-pwd.css";
import NavBar from "../../NavBar";
import { useNavigate } from "react-router-dom";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

export default function ForgotPwd({children }) {
    const [userText, setUserText] = useState("");
    const [codeText, setCodeText] = useState("");     
    const [pwdText, setPwdText] = useState(""); 
    const navigate = useNavigate();    

    const handleResetPwdClick = (e) => {

        var username = userText;
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                   Username: username,
                   Pool: userPool
               });

               cognitoUser.forgotPassword({
            onSuccess: function(result) {
                alert("Codigo de Verificacion Enviado al Mail.");                            
            },
            onFailure: function(err) {
                alert(err);
            }
        //   ,
        //   inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
        //     var verificationCode = prompt('Please input verification code ', '');
        //     var newPassword = prompt('Enter new password ', '');
        //     cognitoUser.confirmPassword(verificationCode, newPassword, this);
        // }
        });
        e.preventDefault();
        console.log('The link was clicked');            
    }; 
    
    const handleConfirmPwdClick = (e) => {

        var username = userText;
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                   Username: username,
                   Pool: userPool
               });

               return new Promise((resolve, reject) => {
                cognitoUser.confirmPassword(codeText, pwdText, {
                onFailure(err) {
                                reject(err);
                                },
                onSuccess() {
                    resolve();
                    alert("Contraseña Actualizada Correctamente.");                            
                    //window.location = '/login-register.html';
                    navigate('/', { replace: true }); 
                    e.preventDefault();
                    console.log('The link was clicked');               
                },
            });
        });
    }; 
    return (
    <>
    <div className="forgot-pwd">
    <div className="container_forgot-pwd">
        <div className="form-forgot-pwd">
            <div>
                <h2>Email</h2>
            </div>
            <input 
            id="regusername" 
            type="email" 
            placeholder="Ingrese su email" 
            value={userText}
            onChange={({target: { value }}) => setUserText(value)}  
            />
        </div>
        <div className="form-forgot-pwd">
            <div>
                <h2>Nueva contraseña</h2>
            </div>
            <input 
            id="regpassword" 
            type="password" 
            placeholder="Ingrese su nueva contraseña" 
            value={pwdText}
            onChange={({target: { value }}) => setPwdText(value)}  
            />
        </div>
        <div className="form-forgot-pwd">
            <div>
                <h2>Código de verificación</h2>
            </div>
            <input 
            id="code" 
            type="number" 
            maxLength="6" 
            placeholder="Ingrese su código de verificación" 
            value={codeText}
            onChange={({target: { value }}) => setCodeText(value)}              
            />
        </div>
        <div>
            <button type="button" className="btn-fgtpwd" onClick={handleResetPwdClick}>Solicitar código</button>
            </div>
            <div>
            <button type="button" className="btn-fgtpwd" onClick={handleConfirmPwdClick}>Cambiar
                Contraseña</button>
        </div>
    </div>
    </div>
    <NavBar />
    </>    
     );
}