import AuthContext from "./index";
import { useState } from "react";

export default function AuthProvider({ children }) {
    const [auth, setAuth] = useState({user: "", pwd: "", name:"", city:"", documento:"", telefono:"", rol: null, tkn: null, isLogged: false });

    return (
    <AuthContext.Provider value={{ auth, setAuth }}>
    {/*<AuthContext.Provider value={{ showAlert: () => alert(10) }}>    */}
            {children}
        </AuthContext.Provider>
    );
}