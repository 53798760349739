import { useContext,useState } from "react";
import AuthContext from "../../../../context/authorizer";
//import { useNavigate  } from "react-router-dom";
//import Admin from "../../../Admin";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
 
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avupdateofferresource';  
var method = 'POST';

export default function UpdateOffer(OfferData) {
    const { auth } = useContext(AuthContext); 
    const [success,setSuccess] = useState(false); 
    // const [detOfertaText, setDetOfertaText] = useState(OfferData.results.JobType);            
   
    const handleUpdateOfferClick = (e) => {
    console.log("Clicked!!!");

    var body = {
            UserOfferId: OfferData.results.UserOfferId,          
            TrainingArea: OfferData.results.TrainingArea,
            JobType: OfferData.results.JobType,
            OfferStatus: "validated"
            };
  
    var additionalParams = {headers: {Authorization: auth.tkn}};  
    apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then(function(result){
        //This is where you would put a success callback
        console.log(result);
      //  alert("Oferta Publicada correctamente, ahora sera visible para los Profesionales.");  
        setSuccess(true);  
    }).catch( function(result){
        //This is where you would put an error callback
        console.log(result);            
    });    
};
if (success)
{
return (<>
        <div>
            Oferta Publicada correctamente, ahora sera visible para los Profesionales.
        </div>  
        <button onClick={OfferData.close} className="btn-aceptar">CERRAR</button>         
        </>)
}
else
{
return (<>
          <div>
            NOMBRE: <h3> {OfferData.results.FullName}</h3>
            MODALIDAD: <h3>{OfferData.results.tipo_servicio}</h3>
            SERVICIO: <h3>{OfferData.results.clase_servicio}</h3>
          </div>
          <div>
            Objetivos y caracteristicas detalladas del Servicio:
            <br></br>
            <h3>{OfferData.results.JobType}</h3>
            {/* <textarea 
              id="detoferta" 
              placeholder="Detalle del Servicio..." 
              value={detOfertaText}
              onChange={({ target: { value } }) => setDetOfertaText(value)}             
              ></textarea> */}
          </div>
          <br></br>
          <div>
            UNA VEZ ACEPTES, LA OFERTA ESTARA VISIBLE PARA LOS PROFESIONALES.
          </div>                      
        <button onClick={handleUpdateOfferClick} className="btn-aceptar">ACEPTAR</button> 
        </>)
}
};