import "./style-footer.css";

export default function ViewModal({children, open, close}) {

    console.log(children);    
    console.log(open);
    console.log(close);

    //if(!open) return null
    return (
        <>
         <div  />
            <div 
            className={open ? 'm-dialog-2' : 'm-dialog-22'}  
          //  style = {DIV_MODAL_STYLE} 
            >
                {/* <div><button onClick={close}>X</button></div> */}
                {children}
            </div>  
            <div onClick={close} className={open ? "background" : "background2"}></div>
        </>
    )
}