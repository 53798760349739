import "./modal.css";

export default function Modal({children, open, close, openstyle, closestyle }) {
    return (
        <>
         <div  />
            <div 
            className={open ? openstyle : closestyle}  
          //  style = {DIV_MODAL_STYLE} 
            >
                {/* <div><button onClick={close}>X</button></div> */}
                {children}
            </div>  
            <div onClick={close} className={open ? "background-opened" : "background-closed"}></div>
        </>
    )
}