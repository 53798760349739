import { useContext } from "react";
import AuthContext from "../../../../context/authorizer";
import { useNavigate  } from "react-router-dom";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
     
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avhealthregofferresource';
var method = 'POST';

export default function RegOffer(OfferData) {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();         
    var offerstatus="in-progress";
    if (OfferData.results.tipo_servicio==="VIRTUAL")
    {
      offerstatus="validated";
    }
    const handleRegOfferClick = (e) => {
    console.log("Clicked!!!");
    console.log(OfferData);
    if (OfferData.results.JobType==="")
    {
        alert("Debe indicar un breve detalle del servicio a tomar");
    }else if (OfferData.results.franja===""){
      alert("Debe seleccionar una Franja Horaria");
    }
    else
    {
      if (window.confirm('Está seguro que quiere registrar esta Oferta?'))
      {
      var timestamp = Date.now();  
      var vroferta = OfferData.results.vr_oferta - (OfferData.results.vr_oferta*0.30);   
      var body = {
          UserOfferId: auth.user,          
          TrainingArea: OfferData.results.TrainingArea,
          CityLocation: auth.city,
          FullName: auth.name,
          JobType: OfferData.results.JobType,
          OfferDate: timestamp.toString(),
          PayOffer: vroferta.toString(),
          OfferStatus: offerstatus,
          BasicInfo: {
            "InfoPersonal": {
              "M": {
                "nombre_completo": {
                  "S": auth.name
                },
                "fecha_nacimiento": {
                  "S": "N/D"
                },
                "telefono": {
                  "S": auth.telefono
                },
                "peso": {
                  "S": "N/D"
                },
                "estatura": {
                  "S": "N/D"
                },
                "antecedentes": {
                  "S": "N/D"
                }
              }
            },
            "ObjetivoOferta": {
              "M": {
                "objetivo": {
                  "S":  OfferData.results.JobType
                },
                "tipo_servicio": {
                  "S":OfferData.results.tipo_servicio
                },
                "clase_servicio": {
                  "S": OfferData.results.clase_servicio
                },
                "personas_add": {
                  "S": OfferData.results.personas_add
                },

                "periodicidad": {
                  "S":  OfferData.results.periodicidad
                },
                "vr_oferta": {
                  "S": OfferData.results.vr_oferta
                },
                "franja_horaria": {
                  "S": OfferData.results.franja
                },               
              }
            }
          }
        };
    
      var additionalParams = {headers: {Authorization: auth.tkn}};  
      apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then(function(result){
          //This is where you would put a success callback
          alert("Oferta Registrada correctamente.");               
          navigate('/', { replace: true });                
      }).catch( function(result){
          //This is where you would put an error callback
          console.log(result);            
      });
    }
  }           
};
 
return (<>
            <button onClick={handleRegOfferClick} className="btn-apply" >APLICAR</button> 
        </>)
};