import ProfResultItem from "./ProfResultItem";
//import "./style-admin.css";
import Loader from "../../../Loader";
import "./verificar-profesional.css"

import { useContext,useEffect,useState } from "react";
import AuthContext from "../../../../context/authorizer";
import apiCall from "../../../../api";
import NavBar from "../../../Home/components/NavBar";


var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
    
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avshowpendvalidprofileresource';  
var method = 'POST';

export default function ShowPendValidateProf() {
        const [isLoading,setIsLoading] = useState(false);
        const [data,setData] = useState([]);    
        const { auth } = useContext(AuthContext);    

        //    var additionalParams = {headers: {Authorization: null}};
       // var additionalParams = {headers: {}}; 
        // var additionalParams = {headers: {Authorization:auth.tkn}}; 
        // var body ={};

        // apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        // .then(function(result){
        //     //This is where you would put a success callback
        //     console.log(result);            

        // }).catch( function(result){
        //     //This is where you would put an error callback
        //     console.log(result);            
        // }); 
        
        let headData = new Headers();    
    
        headData.append('Content-Type', 'application/json');
        headData.append('Accept', 'application/json');
        headData.append('Authorization', auth.tkn );
        headData.append('Origin','*');    
         useEffect(() => {
            const getOffers = async () => {
                try {
                    setIsLoading(true);
                    const response = await apiCall(
                    {
                        url:'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN/avshowpendvalidprofileresource',
                        method: "post",
                        mode: "cors",
                        headers: headData
                    });
                    console.log(response); 
               //     const data = await response.json();
                    setData(response);
                    setIsLoading(false);                
               //     console.log(data);
                } catch(err) {
                    console.error(err);
                }
            };
            getOffers().catch(null);
        }, []);
        
        if (isLoading) {
            return(<>
                <Loader/>
                <NavBar/>
                </>);
        }
        else
        {
            return (
            <>
            <div className="prof-pend-validar">
            <div className="prof-pend-validar-content">PROFESIONALES PENDIENTES DE VERIFICAR</div>
            <div className="verificar-profesionales">
                {data?.map((value)=>
                <ProfResultItem key={value.ProfessionalId} {...value} />)} 
            </div>
            </div>
            <NavBar/>
            </>
            );  
        }
}