import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../views/Home";
import Users from "../views/Users";
import Trainer from "../views/Trainer";
import Services from "../views/Services";
import ChangePwd from "../views/Home/components/Authenticate/ChangePwd";
import ForgotPwd from "../views/Home/components/Authenticate/ForgotPwd";
import Confirm from "../views/Confirm";
import UpdateProfile from "../views/UpdateProfile";
import Loader from "../views/Loader";
import Admin from "../views/Admin";
import OffersApplied from "../views/Admin/components/ShowAppliedOffers";
import ValidateProf from "../views/Admin/components/ValidateProf";
import ShowPendValidateProf from "../views/Admin/components/ShowPendValidateProf";
import AuthContext from "../context/authorizer";
import { useContext } from 'react';

export default function Routing()
{
    const { auth } = useContext(AuthContext); 
    if (auth.rol==="Admin") {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/services" element={<Services modo="users" />} /> 
                    <Route path="/services-trainer" element={<Services modo="trainer" />} />                 
                    <Route path="/services/users" element={<Users/>} /> 
                    <Route path="/services/users/nutricion" element={<Users service="NUTRICION"/>} />
                    <Route path="/services/users/personal-trainer" element={<Users service="PERSONAL_TRAINER"/>} />  
                    <Route path="/services/users/paq-saludable" element={<Users service="PAQUETE_INTEGRAL"/>} />                                                                            
                    <Route path="/services-trainer/trainer" element={<Trainer/>} />                                                   
                    <Route path="/services-trainer/trainer/nutricion" element={<Trainer service="NUTRICION"/>} />
                    <Route path="/services-trainer/trainer/personal-trainer" element={<Trainer service="PERSONAL_TRAINER"/>} />  
                    <Route path="/services-trainer/trainer/paq-saludable" element={<Trainer service="PAQUETE_INTEGRAL"/>} />                                                                            
                    <Route path="/confirm/:id" element={<Confirm/>} /> 
                    <Route path="/changepwd" element={<ChangePwd/>} /> 
                    <Route path="/forgotpwd" element={<ForgotPwd/>} />
                    <Route path="/updateprofile" element={<UpdateProfile/>} />
                    <Route path="/loading" element={<Loader/>} />
                    <Route path="/admin-offers-pend" element={<Admin/>} />  
                    <Route path="/admin-offers-applied" element={<OffersApplied/>} />  
                    <Route path="/detail/:id" element={<ValidateProf/>} />
                    <Route path="/admin-prof-pend-val" element={<ShowPendValidateProf/>} />
                </Routes>
            </Router>
        );
    }
    else if (auth.rol==="Customer") {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/services" element={<Services modo="users" />} /> 
                    <Route path="/services/users" element={<Users/>} /> 
                    <Route path="/services/users/nutricion" element={<Users service="NUTRICION"/>} />
                    <Route path="/services/users/personal-trainer" element={<Users service="PERSONAL_TRAINER"/>} />  
                    <Route path="/services/users/paq-saludable" element={<Users service="PAQUETE_INTEGRAL"/>} />                                                                            
                    <Route path="/confirm/:id" element={<Confirm/>} /> 
                    <Route path="/changepwd" element={<ChangePwd/>} /> 
                    <Route path="/forgotpwd" element={<ForgotPwd/>} />
                    <Route path="/updateprofile" element={<UpdateProfile/>} />
                    <Route path="/loading" element={<Loader/>} />
                </Routes>
            </Router>
        );
    }
    else if (auth.rol==="Professional"){
        return(
            <Router>
               <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/services-trainer" element={<Services modo="trainer" />} />                 
                    <Route path="/services-trainer/trainer" element={<Trainer/>} />                                                   
                    <Route path="/services-trainer/trainer/nutricion" element={<Trainer service="NUTRICION"/>} />
                    <Route path="/services-trainer/trainer/personal-trainer" element={<Trainer service="PERSONAL_TRAINER"/>} />  
                    <Route path="/services-trainer/trainer/paq-saludable" element={<Trainer service="PAQUETE_INTEGRAL"/>} />                                                                            
                    <Route path="/confirm/:id" element={<Confirm/>} /> 
                    <Route path="/changepwd" element={<ChangePwd/>} /> 
                    <Route path="/forgotpwd" element={<ForgotPwd/>} />
                    <Route path="/updateprofile" element={<UpdateProfile/>} />
                    <Route path="/loading" element={<Loader/>} />
                </Routes>
            </Router>        
        );
    }
    else{
        return(
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/confirm/:id" element={<Confirm/>} /> 
                    <Route path="/changepwd" element={<ChangePwd/>} /> 
                    <Route path="/forgotpwd" element={<ForgotPwd/>} />
                    <Route path="/loading" element={<Loader/>} />              
                </Routes>
            </Router>
        );        
    }
}