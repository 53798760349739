import "./style-loader.css";

export default function Loader() {

    //if(!open) return null
    return (
        <div className="style-loader">
         <svg className="style-svg" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" />
         </svg>   
        </div>
    )
}