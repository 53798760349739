import "./style-navbar.css";
import { useState,useContext,useEffect } from 'react'
import { useNavigate  } from "react-router-dom";
import AuthContext from "../../../../context/authorizer";
import Modal from '../../../Modal';
import LogOut from "../LogOut";
//import UilQuestion from '@iconscout/react-unicons/icons/uil-comment-question';
import UilHome from '@iconscout/react-unicons/icons/uil-home';
import UilBag from '@iconscout/react-unicons/icons/uil-bag';
import UilBill from '@iconscout/react-unicons/icons/uil-bill';
import UilTrain from '@iconscout/react-unicons/icons/uil-dumbbell';
import UilLogOut from '@iconscout/react-unicons/icons/uil-signout';
//import UilPlus from '@iconscout/react-unicons/icons/uil-plus-circle';
import UilUserPlus from '@iconscout/react-unicons/icons/uil-user-plus';
import UilWhatsapp from '@iconscout/react-unicons/icons/uil-whatsapp';
import UilFacebook from '@iconscout/react-unicons/icons/uil-facebook-f';
import UilInstagram from '@iconscout/react-unicons/icons/uil-instagram';

import {
  FloatingMenu,
  MainButton,
  ChildButton,
  Directions
} from 'react-floating-button-menu';
import MdAdd from '@iconscout/react-unicons/icons/uil-plus-circle'; //'@material-ui/icons/add';
import MdClose from '@iconscout/react-unicons/icons/uil-plus-circle'; //'@material-ui/icons/clear';
import MdUpdProfile from '@iconscout/react-unicons/icons/uil-user-exclamation';
import MdNews from '@iconscout/react-unicons/icons/uil-newspaper';
//import MdQuestion from '@iconscout/react-unicons/icons/uil-comment-question'; //'@material-ui/icons/favorite';


export default function NavBar() {
    const { auth } = useContext(AuthContext); 
    const navigate = useNavigate();    
    var divBox;
    var buttonBag; 
    var buttonBag2;
    var buttonServices; 
    var buttonValUsers;               
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState(false); 

    const [items, setItems] = useState([]);

    useEffect(() => {
      const items = localStorage.getItem('userData');
      if (items) {
       setItems(items);
      }
    }, []);
    
    //console.log(items);   
    //console.log(auth);    

        const handleClickOnBag = (e) => {
        console.log("Clicked");
        navigate('/admin-offers-pend', { replace: true });           
       }       
           
       const handleClickOnHome = (e) => {
        console.log("Clicked");
        navigate('/', { replace: true });           
       }

       const handleClickOnServices = (e) => {
        console.log("Clicked");
        navigate('/services', { replace: true });           
       }

       const handleClickOnOffers = (e) => {
        console.log("Clicked");
        navigate('/services-trainer', { replace: true }); 
       }       

       const handleClickOnAplliedOffers = (e) => {
        console.log("Clicked");
        navigate('/admin-offers-applied', { replace: true });           
       }       

       const handleClickOnLogout = (e) => {
        console.log("Clicked");
        setIsOpen(true);
       } 

       const handleClickOnProfPendVal = (e) => {
        console.log("Clicked");
        navigate('/admin-prof-pend-val', { replace: true });    
       }
       
       const handleClickOnUpdateProfile = (e) => {
        console.log("Clicked");
        if (auth.rol === null)
        {
          navigate('/', { replace: true });    
        }
        else{
          navigate('/updateprofile', { replace: true });    
        }

       }
       
       const handleClickOnNews = (e) => {
        console.log("Clicked");
        navigate('/', { replace: true });    
       }

       const handleClickOnWhatsAPP = (e) => {
        console.log("Clicked");
        window.location.href = 'https://wa.me/573235133787';        
       } 
       
       const handleClickOnInstagram = (e) => {
        console.log("Clicked");
        window.location.href = 'https://www.instagram.com/avhealth.care/';        
       } 
       
       const handleClickOnFacebook = (e) => {
        console.log("Clicked");
        window.location.href = 'https://www.facebook.com/Avhealthfitness-105070241862037';        
       }              

       
       
        if (auth.isLogged) {
            divBox = <button onClick={handleClickOnLogout} aria-label="SALIR">
                    <i><UilLogOut/></i>
                </button>;
          //if (items==='true')
          if (auth.isEnabled)
          {
            if (auth.rol === "Customer"  )
            {
                buttonServices = <button onClick={handleClickOnServices} aria-label="SERVICIOS">
                <i><UilTrain/></i>
              </button>
            }
            else{
                buttonServices = <button onClick={handleClickOnOffers} aria-label="OFERTAS">
                <i><UilTrain/></i>
              </button>
            }                

            if (auth.rol==="Admin") {
                buttonBag = <button onClick={handleClickOnBag} aria-label="OFERTAS A VALIDAR">
                    <i><UilBag/></i>
                </button>;
    
                buttonBag2 = <button onClick={handleClickOnAplliedOffers} aria-label="OFERTAS PENDIENTES PAGO">
                    <i><UilBill/></i>
                </button>;
                
                buttonValUsers = <button onClick={handleClickOnProfPendVal} aria-label="PROFESIONALES PENDIENTES VALIDAR">
                <i><UilUserPlus/></i>
            </button>;                
            }
          }
        };
if (items==='false')
{
  return(
    <div>
    <nav className="footer-navbar">
      {/* <span className="navbar-badge">20+</span>
      <button>home</button>
      <button className="icon-button">
        <UilSearch/>
      </button> */}
      <div className="navbar-center">
        <button type="button" onClick={handleClickOnHome} aria-label="HOME">
          <i><UilHome/></i>
        </button>
    {divBox} 
  </div>
  </nav>  
  </div>  

  );
}
else
{         
return (
  // <div>
  // <ViewModal open = {isOpen} close = { () => setIsOpen(false)} >Contenido dentro del modal / Content inside of the modal</ViewModal>

  //     <ViewModal />
  // </div>

  <>
    <Modal
          open={isOpen}
          close={() => setIsOpen(false)}
          openstyle="m-logout-dialog-opened"
          closestyle="m-dialog-closed"
          >
          <div>
            <LogOut close={(e) => setIsOpen(false)} />
          </div>
        </Modal>
    <div>
        <nav className="footer-navbar">
          {/* <span className="navbar-badge">20+</span>
          <button>home</button>
          <button className="icon-button">
            <UilSearch/>
          </button> */}
          <div className="navbar-center">
            <button type="button" onClick={handleClickOnHome} aria-label="HOME">
              <i><UilHome/></i>
            </button>
            {buttonBag} 
            {buttonBag2} 
            {buttonServices}
            {buttonValUsers}
        {divBox} 
      </div>
        
        <div className="navbar-end">
<FloatingMenu
            slideSpeed={500}
            direction={Directions.Up}
            spacing={8}
            isOpen={state}
          >
            <MainButton
              iconResting={<MdAdd color="white" style={{ fontSize: 20 }} />}
              iconActive={<MdClose color="white" style={{ fontSize: 20 }} />}
              background="#6dae40"
              onClick={() => setState(!state)}
              size={56}
            />
            <ChildButton
              icon={<MdUpdProfile color="#6dae40" style={{ fontSize: 20 }} />}
              background="white"
              size={40}
              onClick={handleClickOnUpdateProfile}
            />
            <ChildButton
              icon={<MdNews color="#6dae40" style={{ fontSize: 20 }} />}
              background="white"
              size={40}
              onClick={handleClickOnNews}      
            />
            <ChildButton
              icon={<UilInstagram color="#6dae40" style={{ fontSize: 20 }}  />}
              background="white"
              size={40}
              onClick={handleClickOnInstagram}         
            />
            <ChildButton
              icon={<UilFacebook color="#6dae40" style={{ fontSize: 20 }}  />}
              background="white"
              size={40}
              onClick={handleClickOnFacebook}         
            />
            <ChildButton
              icon={<UilWhatsapp color="#6dae40" style={{ fontSize: 20 }}  />}
              background="white"
              size={40}
              onClick={handleClickOnWhatsAPP}         
            />                        
          </FloatingMenu>                           
        </div>     
        </nav>
    </div>

  </>
);
}
}