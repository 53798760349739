import { useContext,useEffect,useState } from "react";
import AuthContext from "../../../../context/authorizer";
import apiCall from "../../../../api";
import AppliedOfferResults from "./AppliedOfferResults";
//import NavMenu from "../../../Home/components/NavMenu";
import NavBar from "../../../Home/components/NavBar";

export default function ShowAppliedOffers() {
    var headData = new Headers();    
    headData.append('Content-Type', 'application/json');
    headData.append('Accept', 'application/json');
    headData.append('Origin','*'); 
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);    
    const { auth } = useContext(AuthContext);
    headData.append('Authorization', auth.tkn );

     useEffect(() => {
        const getOffers = async () => {
            try {
                setIsLoading(true);
                const response = await apiCall(
                {
                    url:'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN/avppliedofferresource',
                    method: "post",
                    mode: "cors",
                    headers: headData
                });
                console.log(response); 
           //     const data = await response.json();
                setData(response);
                setIsLoading(false);                
           //     console.log(data);
            } catch(err) {
                console.error(err);
            }
        };
        getOffers().catch(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []); 
  
    return (
        <div>
        {/* <NavMenu color={"bm-menu-violet"}/> */}
        <AppliedOfferResults results={data} isSearching = {isLoading} />
        <NavBar/>        
        </div>              
    );
}