import { useContext,useEffect,useState } from "react";
import AuthContext from '../../context/authorizer';
import apiCall from "../../api";

import OfferResults from "./components/ShowOffers";
//import NavMenu from "../Home/components/NavMenu";
import NavBar from "../Home/components/NavBar";

export default function Trainer(tiposerv) {
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);    
    const { auth } = useContext(AuthContext);    
  
    let headData = new Headers();    

    headData.append('Content-Type', 'application/json');
    headData.append('Accept', 'application/json');
    headData.append('Authorization', auth.tkn );
    headData.append('Origin','*');    

    useEffect(() => {
        const getOffers = async () => {
            try {
                setIsLoading(true);
                const response = await apiCall(
                {
                    url:'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN/avhealthofferresource',
                    method: "post",
                    mode: "cors",
                    headers: headData
                });
                setData(response);
                setIsLoading(false);                
            } catch(err) {
                console.error(err);
            }
        };
        getOffers().catch(null);
    }, []); 
  
    return (
        <div className="servicios-profesional">
        {/* <NavMenu color={"bm-menu-violet"}/> */}
        <OfferResults results={data} isSearching = {isLoading} tiposervicio={tiposerv.service} />
        <NavBar/>          
        </div>              
    );
}