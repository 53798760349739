import UserProfile from "./components/UserProfile";
import ProfessionalProfile from "./components/ProfessionalProfile";
import NavBar from "../Home/components/NavBar";
import { useContext } from "react";
import AuthContext from '../../context/authorizer';

export default function UpdateProfile() {
    
    const { auth } = useContext(AuthContext);    
    
    if (auth.rol === "Customer"){
        return (
            <div>
            <UserProfile document={auth.documento} telefono={auth.telefono} />
            <NavBar/>             
            </div> 
        );
    
    }else
    {
        return (
            <div>
            <ProfessionalProfile document={auth.documento} telefono={auth.telefono} />
            <NavBar/>                      
            </div> 
        );
    }
}